import React, {Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import { devicesActions } from './store';
import { useEffectAsync } from './reactHelper';
import t from "./common/localization";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReplayIcon from '@material-ui/icons/Replay';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EditCollectionViewDevice from "./EditCollectionViewDevice";
import {map} from "./map/Map";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import {blue} from "@material-ui/core/colors";
//import CardHeader from "@material-ui/core/CardHeader";
//import React, {Fragment, useCallback, useEffect, useState} from 'react';
//import {Accordion, AccordionSummary, AccordionDetails, Typography, FormControlLabel, Checkbox, Button} from '@material-ui/core';
//import Divider from '@material-ui/core/Divider';
//import AddAttributeDialog from "./attributes/AddAttributeDialog";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0.1),
    textTransform: 'none',
  },
  list: {
    maxHeight: '100%',
    overflow: 'auto',

  },
  green: {
    color: theme.palette.common.green,
  },
  red: {
    color: theme.palette.common.red,
  },
  gray: {
    color: theme.palette.common.gray,
  },
  yellow: {
    color: theme.palette.common.yellow,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  configue: {
    color: 'red'
  },
}));
const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return 'green';
    case 'offline':
      return 'gray';
    case 'slowing':
      return 'yellow';
    default:
      return 'red';
  }
};

const DeviceView = ({ updateTimestamp, thisOne, focus,  onMenuClick}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector(state => Object.values(state.devices.items));
  const positions = useSelector(state => Object.values(state.positions));

  const handleClick = (id) => {
    history.push(`/position/`+id);
  };

  const onZoom = async (id) => {
    let position = positions[0][id]
    // if(map.getZoom() < 8  ) {
      if (position != undefined)
      map.flyTo({
        center: [position.longitude, position.latitude],
        zoom: [15]
      });
    // }
    // else
    //   map.flyTo({
    //     center: [position.longitude, position.latitude],
    //     zoom: [2] });
  }

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    }
  }, [updateTimestamp]);

  // const signal = (id) => {
  //   if (positions[0][id])
  //   return positions[0][id].attributes.gpsSgn;
  //   else
  //     return 0;
  // }

  const color = (id) => {
    if (positions[0][id]) {
      if (positions[0][id].attributes.gpsSgn<3)
        return 'red';
      else if (positions[0][id].attributes.gpsSgn>=3 && positions[0][id].attributes.gpsSgn<5)
        return 'yellow'
      else
      return 'green'
    }
    else
      return 'blue';
  }

  return (
      <List className={classes.list}>
        {items.map((item, index, list) => (
            <Fragment key={item.id}>
              <ListItem button key={item.id} onClick={() =>{ dispatch(devicesActions.select(item));} }>
                <ListItemAvatar>
                  <Avatar style={{"backgroundColor":getStatusColor(item.status) }} >
                    <img   className={classes.icon}   src={`images/icon/${item.category || 'default'}.svg`} alt="" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={item.uniqueId} className={item.configured !== "configured" && classes.configue}/>
                <ListItemSecondaryAction>
                  <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                    <MoreVertIcon />
                  </IconButton>
                  <div>
                    {/*<SignalCellularAltIcon style={{ fontSize: 16, verticalAlign: 'middle', marginLeft: 5, color: signal(item.id) <= 2 ? 'red' : 'green', transform: `scale(${2})` }} />*/}
                    <SignalCellularAltIcon style={{ fontSize: 16, verticalAlign: 'middle', marginLeft: 5, color: color(item.id), transform: `scale(${2})` }} />
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
              <Button
                  size="medium"
                  className={classes.margin}
                  color="primary"
                  onClick={() => {
                    handleClick(item.positionId);
                  }}
                  startIcon={<ListAltIcon/>}>
                {t("detail")}
              </Button>
              <Button
                  size="medium"
                  className={classes.margin}
                  color="primary"
                  onClick={() => {
                    onZoom(item.id)
                    //dispatch(devicesActions.select(item));
                  }
                  }
                  startIcon={<LocationOnIcon />}>
                {t("tracking")}
              </Button>
              <Button
                  size="medium"
                  className={classes.margin}
                  color="primary"
                  onClick={() => history.push(`replay/${item.id}`)}
                  startIcon={<ReplayIcon/>}>
                {t("playback")}
              </Button>
            </Fragment>
        ))}
      </List>
  );
}

const DevicesList = (focus) => {
  return (
      <EditCollectionViewDevice content={DeviceView}  editPath="/device" endpoint="devices" />
  );
}

export default DevicesList;
