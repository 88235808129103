import React, { useState } from 'react';
import t from '../../common/localization';
import {useHistory} from "react-router-dom";

const ConfirmPassword = () => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [message1, setMessage1] = useState('');
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== "" && confirmPassword !== '' && password === confirmPassword) {
            const urlParts = window.location.hash.split('/');
            const token = urlParts[2];

            const response = await fetch('/api/session/changepassword', { method: 'POST', body: new URLSearchParams(`password=${password}&token=${token}`) });
            if (response.status === 200) {
                //setMessage(t('Password_validated'))
                history.push('/login')
            }
            else
                setMessage(t('errorMessage'))
        }
        if (password === "" || confirmPassword === '')
            setMessage(t('FillTwoFields'))
    };

    function messageNote(password, confirmedPassword, message) {
        if (message)
            return message
        if (password === "" || confirmedPassword === "")
            return ''
        else if (password !== confirmedPassword)
            return t('Passwords_not_identical');
        else if (password === confirmedPassword)
            return t('Passwords_identical');
    }

    const handelePassword = (event) =>{
        setMessage('')
        setPassword(event)
    }

    const handeleNewPassword = (event) =>{
        setMessage('')
        setConfirmPassword(event)
    }

    return (
        <>
            <div className="container">
                <h1>Password Reset</h1>
                <form onSubmit={handleSubmit} >
                    <label>
                        {t("NewPassword")}
                        <input
                            type="password"
                            value={password}
                            onChange={(event) => handelePassword(event.target.value)}
                            placeholder= {t("NewPassword")}
                        />
                    </label>
                    <br />
                    <label>
                        {t("ConfirmNewPassword")}
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(event) => handeleNewPassword(event.target.value)}
                            placeholder= {t("ConfirmNewPassword")}
                        />
                    </label>
                    <br />
                    <button type="submit">{t("Confirm")}</button>
                </form>

                    {messageNote(password, confirmPassword, message) == t('Passwords_identical') ?

                (<span style={{ color: 'green', fontSize: '20px' }}>{messageNote(password, confirmPassword, message)}</span>) :

                    (<span style={{ color: 'red', fontSize: '20px' }}>{messageNote(password, confirmPassword, message)}</span>)
                }
            </div>
            <style>
                {`
                    .container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100vh;
                    }

                    h1 {
                        margin-bottom: 20px;
                        font-size: 36px;
                        text-align: center;
                        color: #333;
                    }

                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        max-width: 400px;
                        background-color: #fff;
                        border-radius: 8px;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                        padding: 40px;
                    }

                    label {
                        margin-top: 20px;
                        margin-bottom: 10px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                    }

                    input[type="password"] {
                        width: 100%;
                        padding: 12px;
                        border: 2px solid #ddd;
                        border-radius: 4px;
                        font-size: 16px;
                        color: #333;
                        outline: none;
                    }

                    button[type="submit"] {
                        width: 100%;
                        padding: 12px;
                        border: none;
                        border-radius: 4px;
                        font-size: 18px;
                        font-weight: bold;
                        color: #fff;
                        background-color: blue;
                        cursor: pointer;
                        transition: background-color 0.2s ease-in-out;
                    }

                    button[type="submit"]:hover {
                        background-color: green;
                    }

                    p {
                        margin-top: 20px;
                        font-size: 18px;
                        color: #333;
                    }
                `}
            </style>
        </>
    );
};

export default ConfirmPassword;