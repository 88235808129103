import React, { useState } from 'react';
import {
    Button,
    FormControl,
    Grid, IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TableCell, TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import t from "../common/localization";
import ReportLayoutPage from "./ReportLayoutPage";
import {DataGrid} from "@material-ui/data-grid";
import {useSelector} from "react-redux";
import {formatDistance, formatHours, formatDate, formatSpeed, formatVolume, formatAgt, formatWeight} from '../common/formatter';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    gridContainer: {
        margin: theme.spacing(0, -1),
        '& > .MuiGrid-item': {
            padding: theme.spacing(1.5, 1)
        }
    },
    details: {
        flexDirection: 'row',
    },
    columnAction: {
        width: theme.spacing(1),
        padding: theme.spacing(0, 1),
    },
    tableRow: {
        backgroundColor: '#f0f0f0', /* Light gray background */
    },
    tableCell: {
        padding: 8, /* Adjust padding as needed */
        borderBottom: '1px solid #ddd', /* Bottom border */
        width: '46%' /* Set width to 70% of the container */
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'green',
            },
            '&:hover fieldset': {
                borderColor: 'green',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
    greenDeleteIcon: {
        color: 'green',
    },
}));

const Filter = ({ setItems }) => {
    const classes = useStyles();

    const devices = useSelector(state => Object.values(state.devices.items));
    const [deviceId, setDeviceId] = useState();
    const [distance, setDistance] = useState();
    const [weight, setWeight] = useState();

    const [distanceOfThePart, setDistanceOfThePart] = useState([]);
    const [to, setTo] = useState([]);
    const [legs, setLegs] = useState([])

    const showOnly = false;

    const handleClick = (mail, json) => {
        const accept = json ? 'application/json' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        handleSubmit(
            deviceId,
            mail,
            { Accept: accept }
        );
    }

    const handleSubmit = async (deviceId, mail, headers) => {
        const item =
            {
                distanceOfThePart: distanceOfThePart,
                to: to
            }
        const query = new URLSearchParams({ deviceId, mail });
        const response = await fetch(`/api/reports/simulator?${query.toString()}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(item),
        });
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType) {
                if (contentType === 'application/json') {
                    const res = await response.json();
                    setItems(res);
                } else {
                    window.location.assign(window.URL.createObjectURL(await response.blob()));
                }
            }
        }
    }

    const handleAddLegs = () => {
        const newLeg ={
            id: legs.length + 1,
            lf: distance,
            distance: weight
        }
        setLegs([...legs, newLeg]);
        setDistanceOfThePart([...distanceOfThePart, distance]);
        setTo([...to, weight])
    }

    const handleDelete = (id) => {
        setLegs(legs.filter((_, index) => index !== id));
        setDistanceOfThePart(distanceOfThePart.filter((_, index) => index !== id));
        setTo(to.filter((_, index) => index !== id));
    }


    return (
        <>
        <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} sm={6}>
                <FormControl variant="filled" fullWidth>
                    <InputLabel>{t('reportDevice')}</InputLabel>
                    <Select value={deviceId} onChange={(e) => setDeviceId(e.target.value)}>
                        {devices.map((device) => (
                            <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={3}>
                    <FormControl variant="filled" fullWidth>
                        <TextField
                            className={classes.textField}
                            value={distance || ''}
                            onChange={event => setDistance(event.target.value)}
                            label={t('sharedDistance')+': \'Km\''}
                            variant="filled" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl variant="filled" fullWidth>
                        <TextField
                            className={classes.textField}
                            value={weight || ''}
                            onChange={event => setWeight( event.target.value)}
                            label={t('Weight')+': \'Metric Tons\''}
                            variant="filled" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={handleAddLegs}
                >
                    {t('sharedAddLegs')}
                </Button>
                </Grid>
            </Grid>

            {/*
            {legs.map((leg, index) => (
                <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>{leg.lf}</TableCell>
                    <TableCell className={classes.tableCell}>{leg.distance}</TableCell>
                    <IconButton onClick={() => handleDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                </TableRow>
            ))}
                */}

            {/*/!**/}
            {legs.map((leg, index) => (

                <Grid container spacing={2} className={classes.gridContainer}>
                    <Grid item xs={12} sm={3}>
                            <TextField
                                className={classes.textField}
                                value={leg.lf}
                                //onChange={event => setWeight( event.target.value)}
                                variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                            <TextField
                                className={classes.textField}
                                value={leg.distance }
                                //onChange={event => setDistance(event.target.value)}
                                variant="outlined" />
                    </Grid>
                    <IconButton onClick={() => handleDelete(index)}>
                        <DeleteIcon className={classes.greenDeleteIcon}/>
                    </IconButton>
                </Grid>
            ))}
                {/**!/*/}


            <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={!showOnly ? 4 : 12} sm={!showOnly ? 2 : 6}>
                <Button
                    onClick={() => handleClick(false, true)}
                    variant='outlined'
                    color='secondary'
                    fullWidth>
                    {t('reportShow')}
                </Button>
            </Grid>
            {!showOnly &&
            <Grid item xs={4} sm={2}>
                <Button
                    onClick={() => handleClick(false, false)}
                    variant='outlined'
                    color='secondary'
                    fullWidth>
                    {t('reportExport')}
                </Button>
            </Grid>}
            {!showOnly &&
            <Grid item xs={4} sm={2}>
                <Button
                    onClick={() => handleClick(true, false)}
                    variant='outlined'
                    color='secondary'
                    fullWidth>
                    <Typography variant="button" noWrap>{t('reportEmail')}</Typography>
                </Button>
            </Grid>}
        </Grid>
        </>
    );
}



const Simulator = () =>{
    const [items, setItems] = useState([]);
    const columns = [
        {
            headerName: t('positionFixTime'),
            field: 'serverTime',
            type: 'dateTime',
            width: 190,
            // width: theme.dimensions.columnWidthDate,
            valueFormatter: ({ value }) => formatDate(value),
        },{
            headerName: t('positionTotalDistance'),
            field: 'totalDistance',
            type: 'number',
            width: 150,
            // width: theme.dimensions.columnWidthNumber,
            valueFormatter: ({ value }) => formatDistance(value, 'km'),
        },{
            headerName: t('positionDistanceLeg'),
            field: 'legDistance',
            type: 'number',
            width: 150,
            // width: theme.dimensions.columnWidthNumber,
            valueFormatter: ({ value }) => formatDistance(value, 'km'),
        },{
            headerName: t('GHG_Emission'),
            // field: 'serverTime',
            field: 'ghgEmissionSingle',
            type: 'number',
            width: 220,
            // width: theme.dimensions.columnWidthDate,
            valueFormatter: ({ value }) => formatWeight(value, 'metric_tons'),
        },{
            headerName: t('blackCarbonGHG'),
            // field: 'serverTime',
            field: 'blackCarbonSingle',
            type: 'number',
            width: 190,
            // width: theme.dimensions.columnWidthDate,
            valueFormatter: ({ value }) => formatWeight(value, 'grams'),
        }
    ];

    return (
        <ReportLayoutPage filter={<Filter setItems={setItems} />}>
            <DataGrid
                rows={items}
                columns={columns}
                // hideFooter
                autoHeight
                getRowId={() => Math.random()}/>

        </ReportLayoutPage>
    );
}

export default Simulator;

