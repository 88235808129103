import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { positionsActions, devicesActions, sessionActions } from './store';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from './reactHelper';

const displayNotifications = events => {
  if ("Notification" in window) {
    if (Notification.permission === "granted") {
      for (const event of events) {
        const notification = new Notification(`Event: ${event.type}`);
        setTimeout(notification.close.bind(notification), 4 * 1000);
      }
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission(permission => {
        if (permission === "granted") {
          displayNotifications(events);
        }
      });
    }
  }
};

const SocketController = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authenticated = useSelector(state => !!state.session.user);

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(protocol + '//' + window.location.host + '/api/socket');

    socket.onclose = () => {
      setTimeout(() => connectSocket(), 60000);
    };

    // socket.onclose = async (event) => {
    //   if (event.code == 1006) {
    //     history.push('/login');
    //   }else
    //     setTimeout(() => connectSocket(), 60000);
    // };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // let deviceData = data.positions[0].attributes;
      // let yourKeyVariable = "kph";
      // var yourObject = {};
      // yourObject[yourKeyVariable] = "yourValue";
      // deviceData[yourKeyVariable] = 100
      //
      // if (deviceData.hasOwnProperty('batteryvoltage')) {
      //
      // }else {
      //   let batteryvoltage = 'batteryvoltage'
      //   let enginetemp = 'enginetemp';
      //   let fuelelevel = 'fuelelevel';
      //   let fuelpressure = 'fuelpressure';
      //   let fuelrate = 'fuelrate';
      //   let kph = 'kph';
      //   let oiltemp = 'oiltemp';
      //   let rpm = 'rpm';
      //   let torque = 'torque';
      //
      //   data.positions[0].attributes[batteryvoltage] = 0;
      //   data.positions[0].attributes[enginetemp] = 0;
      //   data.positions[0].attributes[fuelelevel] = 0;
      //   data.positions[0].attributes[fuelpressure] = 0;
      //   data.positions[0].attributes[fuelrate] = 0;
      //   data.positions[0].attributes[kph] = 0;
      //   data.positions[0].attributes[oiltemp] = 0;
      //   data.positions[0].attributes[rpm] = 0;
      //   data.positions[0].attributes[torque] = 0;
      //
      //
      //
      //   dispatch(positionsActions.update(data.positions));
      // }
      if (data.devices) {
        dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        dispatch(positionsActions.update(data.positions));
      }
      if (data.events) {
        displayNotifications(data.events);
      }
    };
  }

  useEffectAsync(async () => {
    const response = await fetch('/api/server');
    if (response.ok) {
      dispatch(sessionActions.updateServer(await response.json()));
    }
  }, []);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      }
      connectSocket();
    } else {
      const response = await fetch('/api/session');
      const urlParts = window.location.hash.split('/');
      const firstPart = urlParts[1];
      if (response.ok) {
        dispatch(sessionActions.updateUser(await response.json()));
      } else if (firstPart === 'reset-password') {
        // Redirect to reset password page instead of login page
        history.push('/reset-password'+'/'+urlParts[2]);
      } else {
        history.push('/login');
      }
    }
  }, [authenticated]);

  return null;
}

export default connect()(SocketController);

