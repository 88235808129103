import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';

import t from './common/localization';
import RemoveDialog from './RemoveDialog';
import ActionDialog from './ActionDialog';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const EditCollectionViewDevice = ({ content, editPath, endpoint }) => {
    const classes = useStyles();
    const history = useHistory();

    const [selectedId, setSelectedId] = useState(null);
    const [seleId, setSeleId] = useState(null);
    const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
    const [removeDialogShown, setRemoveDialogShown] = useState(false);
    const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
    const attributes = useSelector(state => state.session.user && state.session.user.attributes);
    const subAdminEnabled = useSelector(state => state.session.user && state.session.user.subadmin);
    const [actionDialogShown, setActionDialogShown] = useState(false);

    const menuShow = (anchorId, itemId) => {
        setSelectedAnchorEl(anchorId);
        setSelectedId(itemId);

    }
    const goTo = (itemId) => {
        setSeleId(itemId)
    }
    const menuHide = () => {
        setSelectedAnchorEl(null);
    }
    const handleAdd = () => {
        history.push(editPath);
        menuHide();
    }
    const handleEdit = () => {
        history.push(`${editPath}/${selectedId}`);
        menuHide();
    }
    const handleRemove = () => {
        setRemoveDialogShown(true);
        menuHide();
    }
    const handleAction = () => {
        setActionDialogShown(true)
        menuHide();
    }
    const handleClose = () => {
        setActionDialogShown(false)
        setUpdateTimestamp(Date.now());
    }
    const handleRemoveResult = () => {
        setRemoveDialogShown(false);
        setUpdateTimestamp(Date.now());
    }

    const Content = content;

    return (
        <>
            <Content updateTimestamp={updateTimestamp} thisOne={seleId} goTo={goTo} onMenuClick={menuShow}/>
            {/*{(attributes.enableAddDevice || subAdminEnabled) &&*/}
            <Tooltip title={t("addNewDevice")}>
                <Fab size="medium" color="primary" className={classes.fab} onClick={handleAdd}>
                    <AddIcon/>
                </Fab>
            </Tooltip>
            {/*}*/}
            <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
                {/*{(attributes.enableAddDevice || subAdminEnabled) &&*/}
                <><MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
                    <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem></>
                {/*}*/}
                <MenuItem onClick={handleAction}>{t('moreactions')}</MenuItem>
            </Menu>
            <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId}
                          onResult={handleRemoveResult}/>
            <ActionDialog open={actionDialogShown} itemId={selectedId} handleClose={handleClose} history={history}/>
        </>
    );
}

export default EditCollectionViewDevice;
