import t from './common/localization'
import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {Snackbar, TextField} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const RemoveDialog = ({ open, endpoint, itemId, onResult }) => {
    const storedValue = localStorage.getItem('tokenThingwings');

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [msg, setmsg] = useState('');

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleRemove = async () => {
    const response = await fetch(`/api/${endpoint}/${itemId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
            'X-Auth-Token': 'Bearer ' + storedValue,
            'password': password
        }});
    if (response.ok) {
      onResult(true);
    }
    else if (response.status === 400){
        const errorText = await response.text();
        let spliteText = errorText.split(' ')[0];
        setmsg(t(spliteText))
        setOpenSnackbar(true)
    }
    else {
        setmsg(t('UnknownProblem'))
        setOpenSnackbar(true)
    }
  };

    function showedMessage() {
      if (endpoint === "devices")
          return t('sharedRemoveBecareful');
      else
        return t('sharedRemoveConfirm')
    }

    return (
        <>
    <Dialog
      open={open}
      onClose={() => { onResult(false) }}>
      <DialogContent>
            <DialogContentText >{showedMessage()}</DialogContentText>
      </DialogContent>
        {endpoint === "devices" &&
        <TextField
            margin="normal"
            value={password || ''}
            onChange={(e) => setPassword(e.target.value)}
            label={t("userPassword")}
            variant="filled"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}/>
        }
      <DialogActions>
        <Button color="primary" onClick={handleRemove} style={{color: 'red'}}>{t('sharedRemove')}</Button>
        <Button autoFocus onClick={() => onResult(false)}>{t('sharedCancel')}</Button>
      </DialogActions>
    </Dialog>
       <Snackbar open={openSnackbar} autoHideDuration={12000} onClose={handleCloseSnackbar} >
          <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
             {msg}
          </Alert>
       </Snackbar>
   </>
  );
};

export default RemoveDialog;
